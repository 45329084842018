import React, { useRef, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DWSModal, DWSButton, InputText, InputTextarea, DWSToast } from '@dws/dws-styleguide';
import { useCreateNewShortcutMutation } from "../../hooks/useCreateNewShortcutMutation";
import { checkFormIsValid } from "./helpers/checkFormIsValid";
import { modalStateReducer, formInitialState } from "./helpers/modalStateReducer";

type CreateShortcutModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export default function CreateShortcutModal({ isOpen, onClose }: Readonly<CreateShortcutModalProps>){
    const { t } = useTranslation();
    const [modalState, dispatch] = useReducer(modalStateReducer, formInitialState);

    const toastRef = useRef(null);

    const { useCreateShortcut } = useCreateNewShortcutMutation(toastRef);
    const { mutate, isSuccess, isLoading } = useCreateShortcut();

    useEffect(() => {
        if (isSuccess) {
            handleCloseModal();
        };
    }, [isSuccess]);

    const handleInputChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, actionType: 'setShortcutName' | 'setShortcutUrl' | 'setShortcutDescription') => {
        const target = event.target as HTMLInputElement | HTMLTextAreaElement;
        dispatch({ type: actionType, payload: target.value });
    };

    function handleConfirmCreateShortcut() {
        const formIsValid = checkFormIsValid(modalState, dispatch);

        if (formIsValid) {
            mutate({ name: modalState.shortcutName, url: modalState.shortcutUrl, description: modalState.shortcutDescription });
        };
    };

    function resetForm() {
        dispatch({ type: 'resetForm', payload: formInitialState});
    };

    function handleCloseModal() {
        resetForm();
        onClose();
    }

    return (
        <>
            <DWSModal
                header={t("shortcuts.new_shortcut")}
                visible={isOpen}
                dismissible={true}
                showCloseIcon={false}
                position="top-right"
                style={{ height: "100vh"}}
                footer={
                    <div>
                        <DWSButton
                            type="secundary"
                            className="cancel-button btn btn-darkest white-text"
                            onClick={handleCloseModal}
                        >
                            {t("general.cancel")}
                        </DWSButton>
                        <DWSButton
                            type="button"
                            className="btn btn-purple"
                            onClick={handleConfirmCreateShortcut}
                            disabled={false}
                            loading={isLoading}
                        >
                            {t("general.add")}
                        </DWSButton>
                    </div>
                }
            >
                <div className="ta-default">
                    <label>{t("shortcuts.create_name")}</label>
                    <InputText
                        className={`shortcut-name-input ${modalState.isShortcutNameValid === false ? "validate invalid" : ""}`}
                        placeholder={t("shortcuts.write_shortcut_name")}
                        onInput={(e: React.FormEvent<HTMLInputElement>) => handleInputChange(e, 'setShortcutName')}
                    />
                </div>
                <div className="ta-default active">
                    <label>{t("shortcuts.add_link")}</label>
                    <InputText
                        className={`shortcut-url-input ${modalState.isShortcutUrlValid === false ? "validate invalid" : ""}`}
                        placeholder={t("shortcuts.add_shortcut_page_link")}
                        onInput={(e: React.FormEvent<HTMLInputElement>) => handleInputChange(e, 'setShortcutUrl')}
                    />
                </div>
                <label>{t("shortcuts.description")}</label>
                <div className="ta-default">
                    <InputTextarea
                        className="shortcut-description-input"
                        placeholder={t("shortcuts.create_description")}
                        maxLength={200}
                        autoResize={false}
                        onInput={(e: React.FormEvent<HTMLInputElement>) => handleInputChange(e, 'setShortcutDescription')}
                    />
                </div>
            </DWSModal>
            <DWSToast ref={toastRef} />
        </>
    );
};