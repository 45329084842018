import putEditShortcut, { EditShortcutProps } from "../helpers/putEditShortcut";
import { MutableRefObject } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export function useEditShortcutMutation(toastRef: MutableRefObject<any> | null = null) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    function useEditShortcut() {
        return useMutation((params: EditShortcutProps) => {
            return putEditShortcut(params);
        }, {
            onSuccess: () => {
                queryClient.invalidateQueries(["shortcuts"]);
                if (toastRef?.current) {
                    console.log("toastRef.current.show edit", toastRef.current);
                    toastRef.current.show({
                        label: t("general.success"),
                        severity:"success", 
                        summary: t("general.success"), 
                        detail: t("shortcuts.message_shortcut_successfully_modified"), 
                        life: 3000
                    });
                }
            },
            onError: () => {
                queryClient.invalidateQueries(["shortcuts"]);
                if (toastRef?.current) {
                    toastRef.current.show({
                        label: t("general.error"),
                        severity:"error", 
                        summary: t("general.error"), 
                        detail: t("shortcuts.message_shortcut_error_edit"), 
                        life: 3000
                    });
                }
            }
        });
    };

    return { useEditShortcut };
};
