import React, { useState } from "react";
import ShortcutsPageHeader from "./components/ShortcutsPageHeader";
import ShortcutsPageContent from "./components/ShortcutsPageContent";

type ShortcutsPageProps = Record<string, unknown> & { children?: React.ReactNode };

export function ShortcutsPage(_: ShortcutsPageProps) {
    const [searchTerm, setSearchTerm] = useState<string>("");
    return (
            <div className="shortcuts-page-container">
                <ShortcutsPageHeader setSearchTerm={setSearchTerm} />
                <ShortcutsPageContent searchTerm={searchTerm} />
            </div>
    );
};
