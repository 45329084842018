import React from "react";
import { useTranslation } from "react-i18next";

export function ShortcutsWidgetHeader() {
    const { t } = useTranslation();

    return (
        <div className="shortcuts-widget-header">
            <div className="shortcuts-widget-header-title">
                {t("shortcuts.my_apps")}
            </div>
            <div className="shortcuts-widget-header-subtitle">
                <span>
                    {t("shortcuts.select_app_guide")}
                    <a href="/shortcuts">{t("shortcuts.application_guide")}</a>
                </span>
            </div>
        </div>
    );
}