import { config } from "../../config";
import { getHeaders } from "../../shared/helpers";
import { FavoriteShortcutItem } from "../../shared/types/FavoriteShortcutItem";
import axios from "axios";


async function getFavoriteShortcutsList() {
    try {
        const url = config.ENV_URL_BCK + "/v1/Shortcut:favorite";
    
        const axiosConfig = { ...getHeaders() };
    
        const response = await axios.get<FavoriteShortcutItem[]>(url, axiosConfig)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error);
            });
        return response.data;
    } catch (error) {
        throw new Error((error as Error).message);
    };
};

export default getFavoriteShortcutsList;