import React from "react";
import { FavoriteShortcutItem } from "../../shared/types/FavoriteShortcutItem";

type ShortcutWidgetCardProps = {
    shortcut: Readonly<FavoriteShortcutItem>;
    index: number;
    onDragStart: (e: React.DragEvent, index: number) => void;
    onDragEnter: (e: React.DragEvent, index: number) => void;
    onDragEnd: () => void;
    onDragOver: (e: React.DragEvent) => void;
};

export default function ShortcutWidgetCard({ shortcut, index, onDragStart, onDragEnter, onDragEnd, onDragOver }: Readonly<ShortcutWidgetCardProps>) {

    return (
        <button 
            key={shortcut.id}
            className="shortcut-widget-card-container"
            draggable
            onDragStart={(e) => onDragStart(e, index)}
            onDragEnter={(e) => onDragEnter(e, index)}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            onClick={() => window.open(shortcut.url, "_blank")}
        >
            <div className="shortcut-widget-card-content">
                {shortcut.icon ? (
                    <img className="shortcut-icon" src={shortcut.icon} alt="icon" />
                ) : null}
                {shortcut.iconBlack ? (
                    <img
                        className="shortcut-icon-black"
                        src={shortcut.iconBlack}
                        alt="icon"
                    />
                ) : null}
                <span className="shortcut-widget-card-name">{shortcut.name}</span>
            </div>
        </button>
    );
};