import postReorderFavoriteShortcut from "../helpers/postReorderFavoriteShortcut";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export function useReorderFavoriteMutation() {
    const queryClient = useQueryClient();
    const mutation = useMutation(postReorderFavoriteShortcut, {
        onSuccess: () => {
            queryClient.invalidateQueries(["favoriteShortcuts"]);
        },
        onError: () => {
            queryClient.invalidateQueries(["favoriteShortcuts"]);
        }
    });

    return mutation;
};