import React from "react";
import { ShortcutsWidgetHeader } from "./components/ShortcutsWidgetHeader";
import { ShortcutsWidgetContent } from "./components/ShortcutsWidgetContent";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

type ShortcutsWidgetProps = Record<string, unknown> & { children?: React.ReactNode };

const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
      },
    },
});

export function ShortcutsWidget(_: ShortcutsWidgetProps) {

    return (
    <QueryClientProvider client={queryClient}>
        <div className="shortcuts-widget-container">
            <ShortcutsWidgetHeader />
            <ShortcutsWidgetContent />
        </div>
    </QueryClientProvider>
    );
};