import React, { useMemo } from "react";
import { useShortcutsData } from "../hooks/useShortcutsData";
import { useTranslation } from "react-i18next";
import { Loading } from '@dws/dws-styleguide';
import {ShortcutPageCard} from "./ShortcutPageCard/ShortcutPageCard";

import { sortShortcuts } from "./../helpers/sortShortcuts";

type ShortcutsPageContentProps = {
    searchTerm: string;
};

export default function ShortcutsPageContent({ searchTerm }: Readonly<ShortcutsPageContentProps>) {
    const { t } = useTranslation();
    const { getShortcutsData } = useShortcutsData();
    const { data: shortcutsList, isLoading, error } = getShortcutsData();

    const filteredShortcutsList = useMemo(() => {
        if (!searchTerm) return sortShortcuts(shortcutsList);

        return shortcutsList?.filter(item => 
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
            item.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [shortcutsList, searchTerm]);

    if (isLoading) return <Loading />;
    if (error) return <div>{t('general.error')}</div>;

    return (
        <div className="shortcut-page-content-container" data-testid="shortcut-page-content-container">
            <div className="shortcut-page-cards-grid">
                {filteredShortcutsList?.map((shortcut) => (
                    <ShortcutPageCard key={shortcut.id} shortcut={shortcut} data-testid="shortcut-page-card" />
                ))}
            </div>

        </div>
    );
};
