import deleteShortcut, { DeleteShortcutProps } from "../helpers/deleteShortcut";
import { MutableRefObject } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export function useDeleteShortcutMutation(toastRef: MutableRefObject<any> | null = null) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    function useDeleteShortcut() {
        return useMutation((params: DeleteShortcutProps) => {
            return deleteShortcut(params);
        }, {
            onSuccess: () => {
                queryClient.invalidateQueries(["shortcuts"]);
                if (!toastRef) return;
                
                toastRef.current.show({
                    label: t("general.success"),
                    severity:"success", 
                    summary: t("general.success"), 
                    detail: t("shortcuts.message_shortcut_successfully_deleted"), 
                    life: 3000
                });

            },
            onError: () => {
                queryClient.invalidateQueries(["shortcuts"]);
                if (toastRef?.current) {
                    toastRef.current.show({
                        label: t("general.error"),
                        severity:"error", 
                        summary: t("general.error"), 
                        detail: t("shortcuts.message_shortcut_error_delete"), 
                        life: 3000
                    });
                }
            }
        });
    };

    return { useDeleteShortcut };
};
