import React, { useEffect, useState, useRef } from "react";
import { useFavoriteShortcutsData } from "../hooks/useFavoriteShortcutsList";
import ShortcutWidgetCard from "./ShortcutWidgetCard";
import { Loading } from "@dws/dws-styleguide";
import { useReorderFavoriteMutation } from "../hooks/useReorderFavoriteShortcut";

export function ShortcutsWidgetContent() {
    const { data: favoriteShortcutsList, isLoading } = useFavoriteShortcutsData();
    const { mutate } = useReorderFavoriteMutation();
    const [shortcuts, setShortcuts] = useState(favoriteShortcutsList || []);
    const dragItem = useRef<number | null>(null);
    const dragOverItem = useRef<number | null>(null);

    useEffect(() => {
        setShortcuts(favoriteShortcutsList || []);
    }, [favoriteShortcutsList]);

    function handleDragStart(e: any, index: number) {
        dragItem.current = index;
    };

    function handleDragEnter(e: any, index: number) {
        dragOverItem.current = index;
    };

    function handleDragEnd() {
        if (dragItem.current === null || dragOverItem.current === null) return;
        const newFavoriteShortcutsList = favoriteShortcutsList ?? [];

        const draggedItemContent = newFavoriteShortcutsList.splice(dragItem.current, 1)[0];

        newFavoriteShortcutsList.splice(dragOverItem.current, 0, draggedItemContent);


        setShortcuts([...newFavoriteShortcutsList]);
        handleSaveOrder();
    };

    function handleSaveOrder() {
        const newOrder = shortcuts.map((shortcut, index) => ({ shortcutId: shortcut.id, order: index }));

        mutate(newOrder);
    };

    if (isLoading) return <Loading />;

    return <div className="shortcuts-widget-content">
        {shortcuts?.map((shortcut, index) => (
            <ShortcutWidgetCard 
                key={shortcut.id} 
                index={index}
                shortcut={shortcut} 
                onDragStart={(e) => handleDragStart(dragItem.current, index)} 
                onDragEnter={(e) => handleDragEnter(dragOverItem.current, index)} 
                onDragEnd={handleDragEnd}
                onDragOver={(e) => e.preventDefault()}
            />
        ))
        }
    </div>;
};