import { config } from "../../config";
import { getHeaders } from "../../shared/helpers";
import axios from "axios";

export type DeleteShortcutProps = {
    id: string;
}

async function deleteShortcut(params: Readonly<DeleteShortcutProps>): Promise<void> {
    try {
        const url = `${config.ENV_URL_BCK}/v1/Shortcut/${params.id}`;
        const axiosConfig = { ...getHeaders(), method: "DELETE" };

        await axios.delete(url, axiosConfig);
    } catch (error) {
        throw new Error((error as Error).message);
    };
};

export default deleteShortcut;