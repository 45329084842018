import React, { useRef, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DWSModal, DWSButton, InputText, InputTextarea, DWSToast } from '@dws/dws-styleguide';
import { checkFormIsValid } from "./helpers/checkFormIsValid";
import { modalStateReducer, formInitialState } from "./helpers/modalStateReducer";
import { ShortcutItem } from "../../../shared/types/ShortcutItem";
import { useEditShortcutMutation } from "../../hooks/useEditShortcutMutation";
import { ModalState } from "./types/ModalState";

type EditShortcutModalProps = {
    isOpen: boolean;
    onClose: (openDeleteModal: boolean) => void;
    shortcutItem: ShortcutItem;
};

export default function EditShortcutModal({ isOpen, onClose, shortcutItem }: Readonly<EditShortcutModalProps>){
    const { t } = useTranslation();

    const editInitialState = {
        ...formInitialState,
        shortcutName: shortcutItem.name,
        shortcutUrl: shortcutItem.url,
        shortcutDescription: shortcutItem.description,
    };

    const [modalState, dispatch] = useReducer(modalStateReducer, editInitialState);

    const toastRef = useRef(null);
    const { useEditShortcut } = useEditShortcutMutation(toastRef);
    const { mutate, isSuccess, isLoading } = useEditShortcut();

    useEffect(() => {
        if (isSuccess) {
            handleCloseModal();
        };
    }, [isSuccess]);

    function handleDeleteShortcut() {
        onClose(true);
    };

    function handleCloseModal() {
        resetForm(editInitialState);
        onClose(false);
    };
    
    function resetForm(state: ModalState) {
        dispatch({ type: 'resetForm', payload: { ...state } });
    };

    function handleConfirmEditShortcut() {
        const formIsValid = checkFormIsValid(modalState, dispatch);

        if (formIsValid) {
            mutate({ id: shortcutItem.id, name: modalState.shortcutName, url: modalState.shortcutUrl, description: modalState.shortcutDescription });
        };
    };
    
    function handleInputChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, actionType: 'setShortcutName' | 'setShortcutUrl' | 'setShortcutDescription') {
        const target = event.target as HTMLInputElement | HTMLTextAreaElement;
        dispatch({ type: actionType, payload: target.value });
    };

    return (
        <>
            <DWSModal
                data-testid="edit-shortcut-modal"
                header={t("shortcuts.edit_shortcut")}
                visible={isOpen}
                dismissible={true}
                showCloseIcon={false}
                position="top-right"
                style={{ height: "100vh"}}
                footer={
                    <>
                        <DWSButton
                            type="secundary"
                            className="cancel-button btn btn-darkest white-text"
                            onClick={handleDeleteShortcut}
                        >
                            {t("general.delete")}
                        </DWSButton>
                        <DWSButton
                            type="secundary"
                            className="cancel-button btn btn-darkest white-text"
                            onClick={handleCloseModal}
                        >
                            {t("general.cancel")}
                        </DWSButton>
                        <DWSButton
                            type="button"
                            className="btn btn-purple"
                            onClick={handleConfirmEditShortcut}
                            disabled={false}
                            loading={isLoading}
                        >
                            {t("general.save")}
                        </DWSButton>
                    </>
                }
            >
                <div className="ta-default">
                    <label>{t("shortcuts.create_name")}</label>
                    <InputText
                        name="shortcutName"
                        className={`shortcut-name-input ${modalState.isShortcutNameValid === false ? "validate invalid" : ""}`}
                        placeholder={t("shortcuts.write_shortcut_name")}
                        onInput={(e: React.FormEvent<HTMLInputElement>) => handleInputChange(e, 'setShortcutName')}
                        value={modalState.shortcutName}
                    />
                </div>
                <div className="ta-default active">
                    <label>{t("shortcuts.add_link")}</label>
                    <InputText
                        name="shortcutUrl"
                        className={`shortcut-url-input ${modalState.isShortcutUrlValid === false ? "validate invalid" : ""}`}
                        placeholder={t("shortcuts.add_shortcut_page_link")}
                        onInput={(e: React.FormEvent<HTMLInputElement>) => handleInputChange(e, 'setShortcutUrl')}
                        value={modalState.shortcutUrl}
                    />
                </div>
                <label>{t("shortcuts.description")}</label>
                <div className="ta-default">
                    <InputTextarea
                        name="shortcutDescription"
                        className="shortcut-description-input"
                        placeholder={t("shortcuts.create_description")}
                        maxLength={200}
                        autoResize={false}
                        onInput={(e: React.FormEvent<HTMLInputElement>) => handleInputChange(e, 'setShortcutDescription')}
                        value={modalState.shortcutDescription}
                    />
                </div>
            </DWSModal>
            <DWSToast ref={toastRef} />
        </>
    );
};
