import { useQuery } from "@tanstack/react-query";
import getShortcutsList from "../helpers/getShortcutsList";

export function useShortcutsData() {
    function getShortcutsData() {
        return useQuery(["shortcuts"], getShortcutsList);
    };

    return { getShortcutsData };
};
