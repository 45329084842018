import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { 
  applicationId, 
  clientToken, 
  site, 
  service, 
  version, 
  aplicationEnvironment
} from "./config";

  datadogRum.init({
    applicationId,
    clientToken,
    site,
    service,
    env: aplicationEnvironment,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    enableExperimentalFeatures: ["clickmap"]
  });
  
  datadogRum.startSessionReplayRecording();
  datadogLogs.init({
    clientToken,
    site,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service,
    env: aplicationEnvironment,
    version,
  });
