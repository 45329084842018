function getAccessToken(): string | null {
  return localStorage.getItem("accessToken");
};

export function getHeaders() {
  const accessToken = getAccessToken();
  return {
    headers: {
      "Accept-Language": localStorage.getItem("i18nextLng") ?? "en",
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  };
};
