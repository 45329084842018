import { config } from "../../config";
import { getHeaders } from "../../shared/helpers";
import axios from "axios";

export type EditShortcutProps = {
    id: string;
    name: string;
    url: string;
    description: string;
}

async function putEditShortcut(params: Readonly<EditShortcutProps>): Promise<void> {
    try {
        const url = `${config.ENV_URL_BCK}/v1/Shortcut/${params.id}`;
        const axiosConfig = { ...getHeaders(), method: "PUT" };

        await axios.put(url, params, axiosConfig)
    } catch (error) {
        throw new Error((error as Error).message);
    };
};

export default putEditShortcut;
