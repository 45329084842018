import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { InputText, DWSButton } from '@dws/dws-styleguide';
import CreateShortcutModal from "./ShortcutPageModal/CreateShortcutModal";

type ShortcutsPageHeaderProps = {
    setSearchTerm: (searchTerm: string) => void;
}

export default function ShortcutsPageHeader({ setSearchTerm }: Readonly<ShortcutsPageHeaderProps>) {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    function handleSearchChange (event: React.ChangeEvent<HTMLInputElement>) {
        setSearchTerm(event.target.value);
    };

    function handleOpenModal() {
        setIsModalOpen(true);
    }

    return (
        <div className="shortcuts-header-container">
            <h1 className="header-title">{t("shortcuts.application_guide")}</h1>
            <p>
                {t('shortcuts.here_find_tools')}
                <br />
                {t('shortcuts.select_theme')}
            </p>
            <div className="search-add-container">
                <InputText
                    className="search-input"
                    placeholder={t("shortcuts.search_app_subject")}
                    onChange={handleSearchChange}
                />
                <DWSButton
                    className="add-shortcut-button"
                    onClick={handleOpenModal}
                >
                    {t("shortcuts.new_shortcut")}
                </DWSButton>
                {isModalOpen && <CreateShortcutModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />}
            </div>
        </div>
    );
};
