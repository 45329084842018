import postToggleShortcutFavorite from "../helpers/postToggleShortcutFavorite";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export function useShortcutFavoriteMutation() {
    const queryClient = useQueryClient();

    function toggleShortcutFavorite() {
        return useMutation((shortcutId: string) => {
            return postToggleShortcutFavorite(shortcutId);
        }, {
            onSuccess: () => {
                queryClient.invalidateQueries(["shortcuts"]);
            },
            onError: () => {
                queryClient.invalidateQueries(["shortcuts"]);
            }
        })
    }

    return { toggleShortcutFavorite };
};
