import { config } from "../../config";
import { getHeaders } from "../../shared/helpers";
import { ShortcutItem } from "../../shared/types/ShortcutItem";
import axios from "axios";


async function getShortcutsList() {
    try {
        const url = config.ENV_URL_BCK + "/v1/Shortcut";
    
        const axiosConfig = { ...getHeaders() };
    
        const response = await axios.get<ShortcutItem[]>(url, axiosConfig)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error);
            });
        return response.data;
    } catch (error) {
        throw new Error((error as Error).message);
    };
};

export default getShortcutsList;