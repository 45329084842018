import { config } from "../../config";
import { getHeaders } from "../../shared/helpers";
import axios from "axios";

export type CreateNewShortcutProps = {
    name: string;
    url: string;
    description: string;
}

async function postCreateNewShortcut(params: Readonly<CreateNewShortcutProps>): Promise<void> {
    try {
        const url = `${config.ENV_URL_BCK}/v1/Shortcut`;
        const axiosConfig = { ...getHeaders(), method: "POST" };

        await axios.post(url, params, axiosConfig);
    } catch (error) {
        throw new Error((error as Error).message);
    };
};

export default postCreateNewShortcut;