import { ActionModalState } from "../types/ActionModalState";
import { ModalState } from "../types/ModalState";

export const formInitialState: ModalState = {
    shortcutName: "",
    shortcutUrl: "",
    shortcutDescription: "",
    isShortcutNameValid: null,
    isShortcutUrlValid: null,
};

export function modalStateReducer(state: ModalState, action: ActionModalState): ModalState {
    switch (action.type) {
        case 'setShortcutName':
            return { ...state, shortcutName: action.payload };
        case 'setShortcutUrl':
            return { ...state, shortcutUrl: action.payload };
        case 'setShortcutDescription':
            return { ...state, shortcutDescription: action.payload };
        case 'setIsShortcutNameValid':
            return { ...state, isShortcutNameValid: action.payload };
        case 'setIsShortcutUrlValid':
            return { ...state, isShortcutUrlValid: action.payload };
        case 'resetForm':
            return { ...action.payload };
        default:
            return state;
    };
};