import React, { useState } from "react";
import { ShortcutItem } from "../../../shared/types/ShortcutItem";
import { useTranslation } from "react-i18next";
import { useShortcutFavoriteMutation } from "../../hooks/useShortcutFavoriteMutation";
import { Loading } from '@dws/dws-styleguide';
import EditShortcutModal from "../ShortcutPageModal/EditShortcutModal";
import ConfirmDeleteShortcutModal from "../ShortcutPageModal/ConfirmDeleteShortcutModal";

type ShortcutPageCardProps = {
    shortcut: ShortcutItem;
};

function FavoriteButtonContent({isLoading = false, isFavorite = false}: Readonly<{isLoading: boolean, isFavorite: boolean}>) {
    if (isLoading) return <Loading size="small" />;

    if (isFavorite) return <>★</>;

    return <>☆</>;
};



export function ShortcutPageCard({shortcut}: Readonly<ShortcutPageCardProps>) {
    const { t } = useTranslation();
    const { toggleShortcutFavorite } = useShortcutFavoriteMutation();
    const { mutate: toggleFavoriteMutation, isLoading: isLoadingToggleFavorite } = toggleShortcutFavorite();
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(false);

    function handleCloseEditModal(openDeleteModal: boolean = false) {
        setIsEditModalOpen(false);

        if (openDeleteModal) return setConfirmDeleteModalOpen(true);
    };

    function handleToggleFavoriteClick() {
        toggleFavoriteMutation(shortcut.id)
    };

    return (
        <div key={shortcut.id} className="shortcut-page-card-wrapper" data-testid="shortcut-card-wrapper">
                <div className="shortcut-card-header">
                    <span className="shortcut-card-name">
                        {shortcut.icon ? <img className='shortcut-icon' src={shortcut.icon} alt="icon" /> : null}
                        {t(shortcut.name)}
                    </span>
                    <button
                        disabled={isLoadingToggleFavorite}
                        onClick={handleToggleFavoriteClick}
                        className="favorite-icon-button"
                    >
                        <FavoriteButtonContent isLoading={isLoadingToggleFavorite} isFavorite={shortcut.isFavorite} />
                    </button>

                </div>
                <span className="shortcut-page-card-description">
                    {shortcut.description ?? ""}
                </span>
                <span className="shortcut-page-card-url-edit-wrapper">
                    <a
                        href={shortcut.url}
                        className="shortcut-page-card-url"
                        target="_blank"
                        data-testid="shortcut-page-card-url"
                    >
                        {t('general.access_the_app')}
                    </a>
                    {
                        !shortcut.isGlobal &&
                            <button
                                disabled={false}
                                onClick={() => setIsEditModalOpen(true)}
                                className="edit-icon-button"
                                data-testid="edit-button"
                            >
                                🖉
                            </button>
                    }
                </span>
                {isEditModalOpen && <EditShortcutModal isOpen={isEditModalOpen} shortcutItem={shortcut} onClose={(openDelModal) => handleCloseEditModal(openDelModal)} />}
                {confirmDeleteModalOpen && <ConfirmDeleteShortcutModal isOpen={confirmDeleteModalOpen} onClose={() => setConfirmDeleteModalOpen(false)} shortcutItemId={shortcut.id} />}
        </div>
    );
};
