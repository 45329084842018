import { ActionModalState } from "../types/ActionModalState";
import { ModalState } from "../types/ModalState";
import { isValidUrl } from "./validateUrl";

export function checkFormIsValid(state: ModalState, dispatch: React.Dispatch<ActionModalState>) {
    let valid = true;

    if (state.shortcutName.length === 0) {
        dispatch({ type: 'setIsShortcutNameValid', payload: false });
        valid = false;
    } else {
        dispatch({ type: 'setIsShortcutNameValid', payload: true });
    };

    if (!isValidUrl(state.shortcutUrl)) {
        dispatch({ type: 'setIsShortcutUrlValid', payload: false });
        valid = false;
    } else {
        dispatch({ type: 'setIsShortcutUrlValid', payload: true });
    };

    return valid;
};