import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DWSModal, DWSButton, DWSToast } from '@dws/dws-styleguide';
import { useDeleteShortcutMutation } from "../../hooks/useDeleteShortcutMutation";
import { ShortcutItem } from "../../../shared/types/ShortcutItem";

type DeleteShortcutModalProps = {
    isOpen: boolean;
    onClose: () => void;
    shortcutItemId: string;
};

export default function ConfirmDeleteShortcutModal({ isOpen, onClose, shortcutItemId }: Readonly<DeleteShortcutModalProps>){
    const { t } = useTranslation();
    const toastRef = useRef(null);
    
    const { useDeleteShortcut } = useDeleteShortcutMutation(toastRef);
    const { mutate, isLoading, isSuccess } = useDeleteShortcut();

    useEffect(() => {
        if (isSuccess) {
            handleCloseModal();
        };
    }, [isSuccess]);

    function handleConfirmDeleteShortcut() {
        mutate({ id: shortcutItemId });
    };

    function handleCloseModal() {
        onClose();
    };

    return (
        <>
            <DWSModal
                header={t("shortcuts.delete_shortcut_header")}
                visible={isOpen}
                dismissible={true}
                showCloseIcon={false}
                position="center"
                data-testid="dws-modal"
                footer={
                    <>
                        <DWSButton
                            type="secundary"
                            className="cancel-button btn btn-darkest white-text"
                            onClick={handleCloseModal}
                            data-testid="cancel-button"
                        >
                            {t("general.cancel")}
                        </DWSButton>
                        <DWSButton
                            type="button"
                            className="btn btn-purple"
                            onClick={handleConfirmDeleteShortcut}
                            disabled={isLoading}
                            loading={isLoading}
                            data-testid="confirm-button"
                        >
                            {t("general.confirm")}
                        </DWSButton>
                    </>
                }
            >
                <div className="ta-default">
                    {t("shortcuts.delete_shortcut_body")}
                </div>
            </DWSModal>
            <DWSToast ref={toastRef} />
        </>
    );
};
