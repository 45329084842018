import { config } from "../../config";
import { getHeaders } from "../../shared/helpers";
import axios from "axios";

async function postToggleShortcutFavorite(id: string): Promise<void> {
    try {
        const url = `${config.ENV_URL_BCK}/v1/Shortcut/${id}:favorite`;
        const axiosConfig = { ...getHeaders(), method: "POST" };

        await axios.post(url, null, axiosConfig);
    } catch (error) {
        throw new Error((error as Error).message);
    };
};

export default postToggleShortcutFavorite;