import { config } from "../../config";
import { getHeaders } from "../../shared/helpers";
import axios from "axios";

type ReorderFavoriteShortcutProps = {
    shortcutId: string;
    order: number;
}

async function postReorderFavoriteShortcut(params: Readonly<ReorderFavoriteShortcutProps[]>): Promise<void> {
    try {
        const url = `${config.ENV_URL_BCK}/v1/Shortcut:reorder-favorite`;
        const axiosConfig = { ...getHeaders(), method: "POST" };

        await axios.post(url, params, axiosConfig);
        
    } catch (error) {
        throw new Error((error as Error).message);
    };
};

export default postReorderFavoriteShortcut;