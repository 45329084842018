import { ShortcutItem } from '../../shared/types/ShortcutItem';

export function sortShortcuts(
    shortcuts: ShortcutItem[] = []
): ShortcutItem[] {
    return shortcuts.sort((a, b) => {
        if (a.isFavorite && !b.isFavorite) return -1;
        else if (!a.isFavorite && b.isFavorite) return 1;
        else return a.name.localeCompare(b.name);
    });
};